import React, { useState } from "react";

import { updateDeliveryMethod } from "@admin/domain/deliveryMethods/api";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import BreadCrumbs from "@admin/components/BreadCrumbs";
import Editor from "@admin/components/Editor";
import Input from "@admin/components/Input";
import DeliveryMethodIconInput from "./DeliveryMethodIconInput";
import Switch from "@admin/components/Switch";
import type { Language } from "@admin/domain/languages/Languages";
import MultiLanguages from "@admin/components/MultiLanguage";
import { v4 as uuid } from "uuid";
import {
    DeliveryMethodBasketAmountConditionModel,
    DeliveryMethodTextConditionModel,
} from "@admin/domain/deliveryMethods/models/DeliveryMethodModel";

const LIST_URL = `/admin/delivery_methods` as const;

type Condition = Partial<DeliveryMethodBasketAmountConditionModel>;
type TextCondition = Partial<DeliveryMethodTextConditionModel>;

export default function DeliveryMethodEditor() {
    const deliveryMethod = window.CMS.deliveryMethod || null;

    if (!deliveryMethod) {
        throw Error(`Delivery method undefined`);
    }

    const initConditions = (deliveryMethod.basket_amount_conditions || []).map(
        (value) => {
            return { ...value, id: uuid() };
        },
    );

    const initTextConditions = (deliveryMethod.text_conditions || []).map(
        (value) => {
            return { ...value, id: uuid() };
        },
    );

    const currentLanguage = useLanguageContext().currentLanguage;

    const [deliveryMethodConditions, setDeliveryMethodConditions] =
        useState<Condition[]>(initConditions);

    const [deliveryMethodTextConditions, setDeliveryMethodTextConditions] =
        useState<TextCondition[]>(initTextConditions);

    const execute = async (body: FormData) => {
        await updateDeliveryMethod(deliveryMethod.id, body);
    };

    return (
        <>
            <BreadCrumbs
                className="mb-5"
                breadCrumbs={[
                    {
                        title: `Способы доставки`,
                        url: `/admin/delivery_methods/menu`,
                    },
                    {
                        title: `Список способов доставки`,
                        url: `/admin/delivery_methods`,
                    },
                    {
                        title:
                            deliveryMethod.texts[currentLanguage]?.title || ``,
                    },
                ]}
            />

            <Editor backUrl={LIST_URL} onSubmit={execute}>
                <Switch
                    label="Скрыть / Показать"
                    name="is_public"
                    defaultChecked={deliveryMethod.is_public}
                />

                <MultiLanguages
                    languageElement={(language: Language) => (
                        <Input
                            label="Название"
                            name={`texts[${language}][title]`}
                            defaultValue={
                                deliveryMethod?.texts[language]?.title || ``
                            }
                        />
                    )}
                />

                <Input
                    label="Стоимость"
                    name="cost"
                    type="number"
                    defaultValue={deliveryMethod.cost / 100}
                />

                <div className="d-flex">
                    <Input
                        label="Минимальный срок доставки"
                        name="delivery_time_min_days"
                        defaultValue={deliveryMethod.delivery_time_min_days}
                    />
                    <Input
                        className={{ block: `ms-4` }}
                        label="Максимальный срок доставки"
                        name="delivery_time_max_days"
                        defaultValue={deliveryMethod.delivery_time_max_days}
                    />
                </div>

                <Input
                    label="Порядок отображения"
                    name="order"
                    type="number"
                    defaultValue={deliveryMethod.order}
                />

                <DeliveryMethodIconInput
                    label="Иконка"
                    name="icon"
                    icon={deliveryMethod.icon}
                />

                <DeliveryMethodIconInput
                    label="Круглая икона"
                    name="icon_circle"
                    icon={deliveryMethod.icon_circle}
                />

                <DeliveryMethodIconInput
                    label="Иконка отображения на карте"
                    name="icon_map_point"
                    icon={deliveryMethod.icon_map_point}
                />

                <div className="p-10">
                    <h3>Текстовые условия для страницы “Доставка”</h3>
                    {deliveryMethodTextConditions?.map((condition, index) => (
                        <div key={index} className="col pb-5">
                            <input
                                type="hidden"
                                name={`text_conditions[${index}][id]`}
                                value={condition?.id}
                            />
                            <Input
                                className={{ block: `ms-4 pb-5` }}
                                label="Текст"
                                name={`text_conditions[${index}][text]`}
                                defaultValue={
                                    condition?.text ? condition.text : ``
                                }
                            />
                            <Switch
                                label="Является ссылкой"
                                name={`text_conditions[${index}][is_link]`}
                                defaultChecked={condition.is_link}
                            />
                            <Input
                                className={{ block: `ms-4` }}
                                label="Ссылка"
                                name={`text_conditions[${index}][link]`}
                                defaultValue={
                                    condition?.link ? condition?.link : ``
                                }
                            />
                            <a
                                className="card-link text-danger"
                                onClick={() => {
                                    setDeliveryMethodTextConditions((prev) =>
                                        prev.filter(
                                            (_, prevIndex) =>
                                                prevIndex !== index,
                                        ),
                                    );
                                }}
                            >
                                Удалить
                            </a>
                        </div>
                    ))}
                    <div className="d-flex justify-content-end mb-5">
                        <button
                            className="btn btn-primary"
                            type="button"
                            onClick={(event) => {
                                event.preventDefault();
                                setDeliveryMethodTextConditions((prevState) => [
                                    ...prevState,
                                    {
                                        id: uuid(),
                                    },
                                ]);
                            }}
                        >
                            Добавить текстовое условие
                        </button>
                    </div>
                </div>

                <div className="p-10">
                    <h3>Условия</h3>
                    {deliveryMethodConditions?.map((condition, index) => (
                        <div key={index} className="col pb-5">
                            <input
                                type="hidden"
                                name={`basket_amount_conditions[${index}][id]`}
                                value={condition?.id}
                            />
                            <br />
                            <h4>Минимальное значение стоимости корзины</h4>
                            <div className="row mb-10">
                                <Input
                                    className={{ block: `ms-4` }}
                                    label="RUB (в р.)"
                                    name={`basket_amount_conditions[${index}][min_basket_amount][rub]`}
                                    defaultValue={
                                        condition?.min_basket_amount
                                            ? (condition?.min_basket_amount
                                                  .rub || 0) / 100
                                            : ``
                                    }
                                />
                                <br />
                                <Input
                                    className={{ block: `ms-4` }}
                                    label="BYN"
                                    name={`basket_amount_conditions[${index}][min_basket_amount][byn]`}
                                    defaultValue={
                                        condition?.min_basket_amount
                                            ? (condition?.min_basket_amount
                                                  .byn || 0) / 100
                                            : ``
                                    }
                                />
                                <br />
                                <Input
                                    className={{ block: `ms-4` }}
                                    label="KZT"
                                    name={`basket_amount_conditions[${index}][min_basket_amount][kzt]`}
                                    defaultValue={
                                        condition?.min_basket_amount
                                            ? (condition?.min_basket_amount
                                                  .kzt || 0) / 100
                                            : ``
                                    }
                                />
                                <br />
                            </div>
                            <h4>Максимальный значение стоимости корзины</h4>
                            <div className="row mb-5">
                                <Input
                                    className={{ block: `ms-4` }}
                                    label="RUB (в р.)"
                                    name={`basket_amount_conditions[${index}][max_basket_amount][rub]`}
                                    defaultValue={
                                        condition?.max_basket_amount
                                            ? (condition?.max_basket_amount
                                                  .rub || 0) / 100
                                            : ``
                                    }
                                />
                                <br />
                                <Input
                                    className={{ block: `ms-4` }}
                                    label="BYN"
                                    name={`basket_amount_conditions[${index}][max_basket_amount][byn]`}
                                    defaultValue={
                                        condition?.max_basket_amount
                                            ? (condition?.max_basket_amount
                                                  .byn || 0) / 100
                                            : ``
                                    }
                                />
                                <br />
                                <Input
                                    className={{ block: `ms-4` }}
                                    label="KZT"
                                    name={`basket_amount_conditions[${index}][max_basket_amount][kzt]`}
                                    defaultValue={
                                        condition?.max_basket_amount
                                            ? (condition?.max_basket_amount
                                                  .kzt || 0) / 100
                                            : ``
                                    }
                                />
                                <br />
                            </div>
                            <h4>Значение стоимости доставки</h4>
                            <div className="row">
                                <Input
                                    className={{ block: `ms-4` }}
                                    label="RUB (в р.)"
                                    name={`basket_amount_conditions[${index}][delivery_cost][rub]`}
                                    defaultValue={
                                        condition?.delivery_cost
                                            ? (condition?.delivery_cost.rub ||
                                                  0) / 100
                                            : ``
                                    }
                                />
                                <br />
                                <Input
                                    className={{ block: `ms-4` }}
                                    label="BYN"
                                    name={`basket_amount_conditions[${index}][delivery_cost][byn]`}
                                    defaultValue={
                                        condition?.delivery_cost
                                            ? (condition?.delivery_cost.byn ||
                                                  0) / 100
                                            : ``
                                    }
                                />
                                <br />
                                <Input
                                    className={{ block: `ms-4` }}
                                    label="KZT"
                                    name={`basket_amount_conditions[${index}][delivery_cost][kzt]`}
                                    defaultValue={
                                        condition?.delivery_cost
                                            ? (condition?.delivery_cost.kzt ||
                                                  0) / 100
                                            : ``
                                    }
                                />
                                <br />
                            </div>
                            <a
                                className="card-link text-danger"
                                onClick={(event) => {
                                    event.preventDefault();
                                    setDeliveryMethodConditions((prev) =>
                                        prev.filter(
                                            (_, prevIndex) =>
                                                prevIndex !== index,
                                        ),
                                    );
                                }}
                            >
                                Удалить
                            </a>
                        </div>
                    ))}
                    <div className="d-flex justify-content-end mb-5">
                        <button
                            className="btn btn-primary"
                            type="button"
                            onClick={() => {
                                setDeliveryMethodConditions((prevState) => [
                                    ...prevState,
                                    {
                                        id: uuid(),
                                    },
                                ]);
                            }}
                        >
                            Добавить условие
                        </button>
                    </div>
                </div>
            </Editor>
        </>
    );
}
