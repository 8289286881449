import React from "react";
import AccessGroupsSelect from "./AccessGroupsSelect";
import AccessObjectsSearch from "./AccessObjectsSearch";
import AccessPermissionsMatrix from "@admin/pages/AccessPermissionsEditor/Matrix";
import AccessPermissionsContextWrapper from "@admin/pages/AccessPermissionsEditor/AccessPermissionsContext";
import LoaderContext from "@admin/components/LoaderContext";

export default function AccessPermissionsEditor() {
    return (
        <LoaderContext>
            <AccessPermissionsContextWrapper>
                <div className="row mb-5">
                    <AccessGroupsSelect />
                    <AccessObjectsSearch />
                </div>
                <AccessPermissionsMatrix />
            </AccessPermissionsContextWrapper>
        </LoaderContext>
    );
}
