export type AccessPermissionModel = {
    id: number;
    action: AccessAction;
    access_group_id: number;
    access_object_id: number;
    is_enabled: boolean;
};

export enum AccessAction {
    Create = `create`,
    Read = `read`,
    Update = `update`,
    Delete = `delete`,
}
