import React from "react";
import { useAccessPermissionsContext } from "@admin/pages/AccessPermissionsEditor/AccessPermissionsContext";
import Alert from "react-bootstrap/Alert";
import { AccessObjectModel } from "@admin/domain/access/models/AccessObjectModel";
import { AccessAction } from "@admin/domain/access/models/AccessPermissionModel";
import AccessCheckBox from "@admin/pages/AccessPermissionsEditor/Matrix/AccessCheckBox";

export default function AccessPermissionsMatrix() {
    const { selectedAccessGroup, selectedAccessObject } =
        useAccessPermissionsContext();

    const accessObjects = useAccessPermissionsContext().accessObjects.filter(
        (accessObject: AccessObjectModel): boolean => {
            if (!selectedAccessGroup) {
                return false;
            }

            if (selectedAccessObject) {
                return accessObject.id === selectedAccessObject.id;
            }

            return accessObject.scope === selectedAccessGroup.scope;
        },
    );

    if (!selectedAccessGroup) {
        return (
            <Alert variant="danger">
                <span>
                    Группа пользователей не выбрана. Выберите группу
                    пользователей
                </span>
            </Alert>
        );
    }

    return (
        <table className="table table-striped gy-7 gs-7 overflow-auto w-100 mb-5">
            <thead>
                <tr>
                    <td scope="col">Объект доступа</td>
                    <td scope="col">Просмотр</td>
                    <td scope="col">Создание</td>
                    <td scope="col">Редактирование</td>
                    <td scope="col">Удаление</td>
                </tr>
            </thead>
            <tbody>
                {accessObjects.map((accessObject: AccessObjectModel) => (
                    <tr key={`${accessObject.id}-${selectedAccessGroup.id}`}>
                        <td>{accessObject.title}</td>
                        <td>
                            <AccessCheckBox
                                accessObject={accessObject}
                                action={AccessAction.Read}
                            />
                        </td>
                        <td>
                            <AccessCheckBox
                                accessObject={accessObject}
                                action={AccessAction.Create}
                            />
                        </td>
                        <td>
                            <AccessCheckBox
                                accessObject={accessObject}
                                action={AccessAction.Update}
                            />
                        </td>
                        <td>
                            <AccessCheckBox
                                accessObject={accessObject}
                                action={AccessAction.Delete}
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
