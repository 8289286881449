import React from "react";
import Input from "@admin/components/Input";
import { ProductPricesModel } from "@admin/domain/products/models/edit/ProductPricesModel";
import CheckBox from "@admin/components/CheckBox";
import { getFormattedDate } from "@admin/utils/dates";

export default function ProductPrices({
    productPrices,
}: {
    productPrices: ProductPricesModel;
}) {
    return (
        <div className="rounded border p-10 mb-5">
            <h2>Цены и остаток</h2>

            <Input
                label="Розничная цена (в копейках)"
                name="prices[retail_price]"
                defaultValue={productPrices.retail_price}
            />

            <Input
                label="Дистрибьютерская цена (в копейках)"
                name="prices[distributor_price]"
                defaultValue={productPrices.distributor_price}
            />

            <Input
                label="Баллы дистрибьютора"
                name="prices[distributor_point_reward]"
                defaultValue={productPrices.distributor_point_reward}
            />

            <Input
                label="Баллы автозаказа"
                name="prices[auto_order_point_reward]"
                defaultValue={productPrices.auto_order_point_reward}
            />

            <Input
                label="Количество в запасе"
                name="prices[quantity_in_stock]"
                defaultValue={productPrices.quantity_in_stock}
            />

            {productPrices.simple_promotion && (
                <>
                    <h4>Скидка на товар</h4>

                    <Input
                        label="Розничная цена по акции (в копейках)"
                        name="prices[simple_promotion][prices][retail_price]"
                        defaultValue={
                            productPrices.simple_promotion.prices.retail_price
                        }
                    />

                    <Input
                        label="Дистрибьютерская цена по акции (в копейках)"
                        name="prices[simple_promotion][prices][distributor_price]"
                        defaultValue={
                            productPrices.simple_promotion.prices
                                .distributor_price
                        }
                    />

                    <Input
                        label="Характеристика 1C"
                        name="prices[simple_promotion][characteristic_id]"
                        defaultValue={
                            productPrices.simple_promotion.characteristic_id
                        }
                    />

                    <Input
                        label="Процент скидки"
                        name="prices[simple_promotion][percent]"
                        defaultValue={productPrices.simple_promotion.percent}
                    />

                    <CheckBox
                        name="prices[simple_promotion][percent_is_visible]"
                        label="Показывать процент скидки"
                        defaultChecked={
                            productPrices.simple_promotion.percent_is_visible
                        }
                    />

                    <CheckBox
                        name="prices[simple_promotion][old_price_is_visible]"
                        label="Показывать старую цену"
                        defaultChecked={
                            productPrices.simple_promotion.old_price_is_visible
                        }
                    />

                    <div className="d-flex mb-5">
                        <Input
                            className={{ block: `me-5` }}
                            label="Дата начала скидки"
                            defaultValue={
                                productPrices.simple_promotion.starts_at
                                    ? getFormattedDate(
                                          productPrices.simple_promotion
                                              .starts_at,
                                      )
                                    : undefined
                            }
                            required
                            type="datetime-local"
                            name="prices[simple_promotion][starts_at]"
                        />

                        <Input
                            label="Дата конца скидки"
                            defaultValue={
                                productPrices.simple_promotion.ends_at
                                    ? getFormattedDate(
                                          productPrices.simple_promotion
                                              .ends_at,
                                      )
                                    : undefined
                            }
                            required
                            type="datetime-local"
                            name="prices[simple_promotion][ends_at]"
                        />
                    </div>
                </>
            )}

            <CheckBox label="Скоро закончится" name="prices[low_stock]" />
        </div>
    );
}
