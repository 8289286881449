import React from "react";
import { MainBlockBannerModel } from "@admin/domain/uniquePages/models/MainPageModel";
import { Language } from "@admin/domain/languages/Languages";
import Input from "@admin/components/Input";
import MultiLanguages from "@admin/components/MultiLanguage";

type Props = {
    banner: MainBlockBannerModel;
    index: number;
};

export default function BannerFields({ banner, index }: Props) {
    return (
        <div className="card-text mb-3">
            <div>
                <MultiLanguages
                    languageElement={(language: Language) => (
                        <Input
                            label="Заголовок"
                            key={language}
                            name={`content[main_block__banners][${index}][title][${language}]`}
                            value={banner.title[language]}
                        />
                    )}
                />
            </div>

            <div>
                <MultiLanguages
                    languageElement={(language: Language) => (
                        <Input
                            label="Описание"
                            key={language}
                            name={`content[main_block__banners][${index}][description][${language}]`}
                            value={banner.description[language]}
                        />
                    )}
                />
            </div>

            <div>
                <MultiLanguages
                    languageElement={(language: Language) => (
                        <Input
                            label="Текст кнопки"
                            key={language}
                            name={`content[main_block__banners][${index}][button_text][${language}]`}
                            value={banner.button_text[language]}
                        />
                    )}
                />
            </div>

            <div>
                <span className="fw-bolder">Ссылка: </span> {banner.url}
                <input
                    name={`content[main_block__banners][${index}][url]`}
                    type="hidden"
                    value={banner.url}
                />
            </div>

            <div>
                <MultiLanguages
                    languageElement={(language: Language) => (
                        <Input
                            label="Тэг"
                            key={language}
                            name={`content[main_block__banners][${index}][tag][${language}]`}
                            value={banner.tag[language]}
                        />
                    )}
                />
            </div>

            {banner.with_mobile_app_file && (
                <>
                    <div>
                        <MultiLanguages
                            languageElement={(language: Language) => (
                                <Input
                                    label="Заголовок для МП"
                                    key={language}
                                    name={`content[main_block__banners][${index}][mobile_app_title][${language}]`}
                                    value={banner.mobile_app_title[language]}
                                />
                            )}
                        />
                    </div>

                    <div>
                        <MultiLanguages
                            languageElement={(language: Language) => (
                                <Input
                                    label="Описание для МП"
                                    key={language}
                                    name={`content[main_block__banners][${index}][mobile_app_description][${language}]`}
                                    value={
                                        banner.mobile_app_description[language]
                                    }
                                />
                            )}
                        />
                    </div>

                    <div>
                        <span className="fw-bolder">Ссылка для МП: </span>
                        {` `}
                        {banner.mobile_app_url}
                        <input
                            name={`content[main_block__banners][${index}][mobile_app_url]`}
                            type="hidden"
                            value={banner.mobile_app_url || ``}
                        />
                    </div>
                </>
            )}
        </div>
    );
}
