import { DateTime } from "luxon";

export const now = (): string => {
    const date = new Date();
    return getFormattedDate(date);
};

export const getFormattedDate = (date: Date | string): string => {
    if (!(date instanceof Date)) {
        date = new Date(date);
    }

    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, `0`)}-${String(date.getDate()).padStart(2, `0`)}T${String(date.getHours()).padStart(2, `0`)}:${String(date.getMinutes()).padStart(2, `0`)}`;
};

export const prettyDate = (date: string): string => {
    return DateTime.fromSQL(date).toFormat(`yyyy.LL.dd HH:mm`);
};
