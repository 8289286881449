import React, { createContext, ReactNode, useContext, useEffect } from "react";

const Context = createContext<any>(undefined as any);

export function useDataContext<T>(): T {
    return useContext(Context).data;
}

type Props<T> = {
    loader: () => Promise<T>;
    children: ReactNode;
};

export default function DataLoader<T>({ loader, children }: Props<T>) {
    const [data, setData] = React.useState<unknown>(null);

    useEffect((): void => {
        // noinspection JSIgnoredPromiseFromCall
        (async (): Promise<void> => {
            setData(await loader());
        })();

        return;
    }, []);

    if (!data) {
        return null;
    }

    return (
        <Context.Provider
            value={{
                data,
            }}
        >
            {children}
        </Context.Provider>
    );
}
