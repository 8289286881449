import React, {
    type FormEvent,
    type ReactNode,
    type SyntheticEvent,
    useEffect,
    useRef,
    useState,
} from "react";
import { Alert, Modal } from "react-bootstrap";
import isFunction from "lodash-es/isFunction";

import ValidationError from "@admin/domain/api/ValidationError";
import FormErrorsContext, { useSetInvalidFields } from "../FormErrorsContext";

type Props = {
    children: ReactNode;
    backUrl?: string;
    canReset?: boolean;
    onSubmit: (body: FormData) => Promise<false | string | void>;
    onReset?: (event: FormEvent) => void;
    prepareBody?: (body: FormData) => void; // Мутируем FormData из аргумента, поэтому функция ничего не возвращает
    additionalButtons?: ({ isLoading }: { isLoading: boolean }) => ReactNode;
};

function Form({
    children,
    backUrl,
    canReset = true,
    onSubmit: execute,
    onReset: reset,
    prepareBody,
    additionalButtons,
}: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [messages, setMessages] = useState<string[]>([]);
    const [success, setSuccess] = useState<boolean>(true);
    const setInvalidFields = useSetInvalidFields();
    const [confirmationRequest, setConfirmationRequest] = useState<
        string | null
    >(null);
    const postConfirmationAction = useRef<(() => void) | null>(null);

    useEffect((): void => {
        const anchor = window.location.hash;

        if (anchor) {
            const $el = document.querySelector(anchor);

            if (!$el) {
                return;
            }

            const elOffset = $el.getBoundingClientRect().top + window.scrollY;

            let headerOffset = 0;
            const $header = document.querySelector(`#kt_header`);
            if ($header) {
                headerOffset = $header.clientHeight;
            }

            window.scroll(0, elOffset - headerOffset);
        }
    }, []);

    const resetState = (): void => {
        setMessages([]);
        setSuccess(true);
        setInvalidFields(new Set());
    };

    const onSubmit = async (event: FormEvent): Promise<void> => {
        event.preventDefault();

        resetState();
        setIsLoading(true);

        try {
            const body = new FormData(event.target as HTMLFormElement);

            if (isFunction(prepareBody)) {
                prepareBody(body);
            }

            const result = await execute(body as FormData);

            setMessages([`Изменения сохранены`]);
            setSuccess(true);

            if (result === false) {
                return;
            }

            if (result || backUrl) {
                setTimeout(
                    () => window.location.assign((result || backUrl) as string),
                    1000,
                );
            }
        } catch (error) {
            if (error instanceof ValidationError) {
                setMessages(error.errors);
                setInvalidFields(error.fields);
            } else {
                setMessages([(error as Error).message as string]);
            }

            setSuccess(false);
        } finally {
            setIsLoading(false);
        }
    };

    const onReset = (event: FormEvent) => {
        if (confirmationRequest) {
            resetState();

            if (reset) {
                reset(event);
            }

            return;
        }

        if (isLoading) {
            event.preventDefault();
            return;
        }

        const $form = event.target as HTMLFormElement;

        event.preventDefault();
        event.stopPropagation();

        setConfirmationRequest(
            `Вы действительно хотите отменить все несохранённые изменения?`,
        );

        postConfirmationAction.current = () => $form.reset();
    };

    const onLeave = (event: SyntheticEvent) => {
        if (confirmationRequest) {
            return;
        }

        if (isLoading) {
            event.preventDefault();
            return;
        }

        const $link = event.target as HTMLAnchorElement;

        event.preventDefault();
        event.stopPropagation();

        setConfirmationRequest(`Вы действительно хотите покинуть форму?`);

        postConfirmationAction.current = () => $link.click();
    };

    return (
        <form onSubmit={onSubmit} onReset={onReset}>
            <div className="d-flex justify-content-center">
                <div className="flex-grow-1 mw-800px">
                    {children}

                    <div className="separator mt-8" />

                    {messages.length > 0 &&
                        (success ? (
                            <Alert
                                variant="success"
                                dismissible
                                className="mt-4"
                            >
                                {messages.map((message) => (
                                    <p key={message} className="mb-0">
                                        {message}
                                    </p>
                                ))}
                            </Alert>
                        ) : (
                            <Alert
                                variant="danger"
                                dismissible
                                className="mt-4"
                            >
                                <h4 className="alert-heading">
                                    Выявлены ошибки заполнения!
                                </h4>
                                <div className="separator" />
                                {messages.map((message) => (
                                    <p key={message} className="mb-0">
                                        {message}
                                    </p>
                                ))}
                            </Alert>
                        ))}

                    <div className="d-flex justify-content-start mt-8">
                        <button
                            type="submit"
                            className="btn btn-primary me-4"
                            disabled={isLoading}
                        >
                            Сохранить
                        </button>

                        {canReset && (
                            <button
                                type="reset"
                                className="btn btn-secondary me-4"
                                disabled={isLoading}
                            >
                                Отменить
                            </button>
                        )}

                        {additionalButtons && additionalButtons({ isLoading })}

                        {backUrl && (
                            <a
                                href={backUrl}
                                className="btn btn-secondary me-4"
                                onClick={onLeave}
                            >
                                Вернуться в список
                            </a>
                        )}
                    </div>

                    <Modal
                        centered
                        show={Boolean(confirmationRequest)}
                        onHide={() => setConfirmationRequest(null)}
                    >
                        <Modal.Header>
                            <Modal.Title>{confirmationRequest}</Modal.Title>
                        </Modal.Header>
                        <Modal.Footer>
                            <button
                                className="btn btn-secondary"
                                onClick={() => {
                                    setConfirmationRequest(null);
                                    postConfirmationAction.current = null;
                                }}
                            >
                                Нет
                            </button>
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    if (postConfirmationAction.current) {
                                        postConfirmationAction.current();
                                    }
                                    setConfirmationRequest(null);
                                    postConfirmationAction.current = null;
                                }}
                            >
                                Да
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </form>
    );
}

export default function Editor({ children, ...props }: Props): ReactNode {
    return (
        <FormErrorsContext>
            <Form {...props}>{children}</Form>
        </FormErrorsContext>
    );
}
