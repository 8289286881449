import { useOrderContext } from "@admin/pages/OrderEditor/OrderContext";
import { getOrder } from "@admin/domain/orders/api";
import { EditOrderData } from "@admin/domain/orders/dto/EditOrderData";
import { useLoaderContext } from "@admin/components/LoaderContext";

export const useOrderHooks = () => {
    const { order, setOrder } = useOrderContext();
    const { request } = useLoaderContext();

    const actualizeOrder = async (): Promise<EditOrderData> => {
        const { order: actualOrder } = await getOrder(order.id);
        setOrder(actualOrder);

        return actualOrder;
    };

    const orderRequest = (callback: () => Promise<null | void>): void =>
        request(async (): Promise<void> => {
            await callback();
            await actualizeOrder();
        });

    return {
        actualizeOrder,
        orderRequest,
    };
};
