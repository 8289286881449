import { createContext, type ReactNode, useContext, useState } from "react";
import { ResponseErrorInterface } from "@admin/domain/api/ResponseErrorInterface";

type ContextType = {
    isLoading: boolean;
    messages: string[];
    errorCode: number | null;
    startLoading: () => void;
    stopLoading: () => void;
    clearMessages: () => void;
    setErrorsFromResponse: (e: ResponseErrorInterface) => void;
    request: (callback: () => Promise<void>) => void;
};

const Context = createContext<ContextType>(undefined as any);

export function useLoaderContext() {
    return useContext(Context);
}

export default function LoaderContext({ children }: { children: ReactNode }) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [messages, setMessages] = useState<string[]>([]);
    const [errorCode, setErrorCode] = useState<number | null>(null);

    const startLoading = (): void => setIsLoading(true);
    const stopLoading = (): void => setIsLoading(false);
    const clearMessages = (): void => setMessages([]);
    const setErrorsFromResponse = (e: ResponseErrorInterface): void => {
        stopLoading();
        setMessages(e.errors);
        setErrorCode(e.code);
    };

    const request = async (callback: () => Promise<void>): Promise<void> => {
        startLoading();

        try {
            await callback();
            clearMessages();
        } catch (e) {
            setErrorsFromResponse(e as ResponseErrorInterface);
        } finally {
            stopLoading();
        }
    };

    return (
        <Context.Provider
            value={{
                isLoading,
                messages,
                errorCode,
                clearMessages,
                startLoading,
                stopLoading,
                setErrorsFromResponse,
                request,
            }}
        >
            {children}
        </Context.Provider>
    );
}
