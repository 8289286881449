import React from "react";
import { useDataContext } from "@admin/components/DataLoader";
import BasePagination from "@admin/components/Pagination";
import { LogData } from "@admin/domain/logs/dto/LogItem";

export default function Pagination() {
    const { paginator } = useDataContext<LogData>();

    return <BasePagination paginator={paginator} />;
}
