import React, { useState } from "react";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import { DeliveryMethodNotificationModel } from "@admin/domain/deliveryMethods/models/DeliveryMethodNotificationModel";
import { Override } from "@admin/utils/overrideType";
import Sortable from "@admin/components/Sortable";
import { storeOrUpdateDeliveryMethodNotifications } from "@admin/domain/deliveryMethods/api";
import DeliveryMethodNotificationModal from "./DeliveryMethodNotificationModal";
import DeliveryMethodDeleteAllNotificationModal from "./DeliveryMethodDeleteAllNotificationModal";
import BreadCrumbs from "@admin/components/BreadCrumbs";
import Editor from "@admin/components/Editor";

export type Notification = Override<
    DeliveryMethodNotificationModel,
    { id: string | number }
>;

export default function DeliveryMethodNotificationsEditor() {
    const currentLanguage = useLanguageContext().currentLanguage;

    const [showModal, setShowModal] = useState({
        addModal: false,
        deleteModal: false,
    });

    const [notifications, setNotifications] = useState<Notification[]>(
        window.CMS.deliveryMethodNotifications || [],
    );

    const [editableNotification, setEditableNotification] =
        useState<Notification | null>(null);

    const addNotification = (notification: Notification) =>
        setNotifications((prev) => [...prev, notification]);

    const editNotification = (notification: Notification) => {
        setNotifications((prev) =>
            prev.map((value) => {
                if (value.id === notification.id) value = notification;
                return value;
            }),
        );
        setEditableNotification(null);
    };

    const execute = async (body: FormData) => {
        await storeOrUpdateDeliveryMethodNotifications(body);
    };

    return (
        <>
            <BreadCrumbs
                className="mb-5"
                breadCrumbs={[
                    {
                        title: `Способы доставки`,
                        url: `/admin/delivery_methods/menu`,
                    },
                    {
                        title: `Общие настройки доставки`,
                        url: `/admin/delivery_methods/settings`,
                    },
                    { title: `Уведомления` },
                ]}
            />

            <Editor onSubmit={execute}>
                <Sortable list={notifications} setList={setNotifications}>
                    {notifications.map((notification, index) => (
                        <div
                            key={notification.id}
                            className="mb-4 card rounded border cursor-pointer"
                        >
                            <div className="card-body">
                                {typeof notification.id === `number` && (
                                    <input
                                        type="hidden"
                                        name={`notification_list[${index}][id]`}
                                        value={notification.id}
                                    />
                                )}

                                <h5 className="card-title">
                                    {notification.texts[currentLanguage]?.title}
                                </h5>
                                <p className="card-text">
                                    {notification.texts[currentLanguage]?.text}
                                </p>

                                {Object.entries(notification.texts).map(
                                    ([language, text]) => (
                                        <div key={language}>
                                            <input
                                                type="hidden"
                                                name={`notification_list[${index}][texts][${language}][title]`}
                                                value={text.title}
                                            />
                                            <input
                                                type="hidden"
                                                name={`notification_list[${index}][texts][${language}][text]`}
                                                value={text.text}
                                            />
                                        </div>
                                    ),
                                )}

                                <a
                                    className="card-link text-primary cursor-pointer"
                                    onClick={() => {
                                        setEditableNotification(notification);
                                        setShowModal((prev) => ({
                                            ...prev,
                                            addModal: true,
                                        }));
                                    }}
                                >
                                    Редактировать
                                </a>

                                <a
                                    className="card-link text-danger cursor-pointer"
                                    onClick={() =>
                                        setNotifications((prev) =>
                                            prev.filter(
                                                (value) =>
                                                    value.id !==
                                                    notification.id,
                                            ),
                                        )
                                    }
                                >
                                    Удалить
                                </a>
                            </div>
                        </div>
                    ))}
                </Sortable>

                <div className="d-flex justify-content-between mb-5">
                    <button
                        className="btn btn-danger"
                        type="button"
                        onClick={() =>
                            setShowModal((prev) => ({
                                ...prev,
                                deleteModal: true,
                            }))
                        }
                    >
                        Удалить все уведомления
                    </button>

                    <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                            setEditableNotification(null);
                            setShowModal((prev) => ({
                                ...prev,
                                addModal: true,
                            }));
                        }}
                    >
                        Добавить уведомление
                    </button>
                </div>
            </Editor>

            <DeliveryMethodNotificationModal
                showModal={showModal.addModal}
                closeModal={() =>
                    setShowModal((prev) => ({ ...prev, addModal: false }))
                }
                onSubmit={
                    editableNotification ? editNotification : addNotification
                }
                editableNotification={editableNotification}
            />

            <DeliveryMethodDeleteAllNotificationModal
                showModal={showModal.deleteModal}
                closeModal={() =>
                    setShowModal((prev) => ({
                        ...prev,
                        deleteModal: false,
                    }))
                }
            />
        </>
    );
}
