import React, { useEffect } from "react";
import { OrderReceiptModel } from "@admin/domain/orders/models/OrderReceiptModel";
import Input from "@admin/components/Input";
import ReceiptData from "@admin/pages/OrderEditor/Fiscalization/ReceiptData";
import { useOrderHooks } from "@admin/pages/OrderEditor/hooks";
import { useOrderContext } from "@admin/pages/OrderEditor/OrderContext";
import { storeOrderReceiptUrls } from "@admin/domain/orders/api";
import { prettyDate } from "@admin/utils/dates";

type Props = {
    receipts: OrderReceiptModel[];
    currencySymbol: string;
};

export default function Receipts({ receipts, currencySymbol }: Props) {
    const { order } = useOrderContext();
    const { orderRequest } = useOrderHooks();

    useEffect((): void => {
        if (
            order.receipts.filter(
                (receipt: OrderReceiptModel): boolean => receipt.url === null,
            ).length > 0
        ) {
            orderRequest(
                async (): Promise<null> =>
                    await storeOrderReceiptUrls(order.id),
            );
        }
    }, []);

    return receipts.map((receipt: OrderReceiptModel) => (
        <div key={receipt.external_receipt_id}>
            <div className="d-flex justify-content-start">
                <Input
                    label="UUID чека"
                    defaultValue={receipt.external_receipt_id}
                    disabled
                    className={{ block: `col-3 mb-5 me-2` }}
                />
                <Input
                    label="Дата создания чека"
                    defaultValue={prettyDate(receipt.created_at)}
                    disabled
                    className={{ block: `col-3 mb-5 me-2` }}
                />
                <Input
                    label="Сумма"
                    defaultValue={`${(receipt.data?.order?.total_amount || 0) / 100} ${currencySymbol}`}
                    disabled
                    className={{ block: `col-3 mb-5 me-2` }}
                />
                <Input
                    label="Url"
                    defaultValue={receipt.url || ``}
                    disabled
                    className={{ block: `col-3 mb-5` }}
                />
            </div>
            <ReceiptData data={receipt.data} />
        </div>
    ));
}
