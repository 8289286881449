import React from "react";
import { useOrderContext } from "@admin/pages/OrderEditor/OrderContext";
import { useLoaderContext } from "@admin/components/LoaderContext";
import { useOrderHooks } from "@admin/pages/OrderEditor/hooks";
import { storeOrderReceipt } from "@admin/domain/orders/api";
import formatMoney from "@admin/domain/orders/formatMoney";
import StoreCustomReceiptModal from "@admin/pages/OrderEditor/Fiscalization/StoreCustomReceiptModal";
import ConsolidatedOrderReceiptMessage from "@admin/pages/OrderEditor/Fiscalization/ConsolidatedOrderReceiptMessage";
import Receipts from "@admin/pages/OrderEditor/Fiscalization/Receipts";

export default function Fiscalization() {
    const { order } = useOrderContext();
    const [modalIsOpened, setModalIsOpened] = React.useState<boolean>(false);

    const { isLoading, request } = useLoaderContext();
    const { actualizeOrder } = useOrderHooks();
    const storeReceipt = (): void => {
        request(async (): Promise<void> => {
            await storeOrderReceipt(order.id);
            await actualizeOrder();
        });
    };

    return (
        <div className="rounded border p-10 my-5">
            <h4 className="mb-5">Фискализация</h4>
            <ConsolidatedOrderReceiptMessage order={order} />

            <Receipts
                receipts={order.receipts}
                currencySymbol={order.currency_symbol}
            />

            {order.paid_at && order.receipts.length === 0 && (
                <div className="d-flex justify-content-start">
                    <button
                        className="btn btn-info me-2 mb-2"
                        disabled={isLoading}
                        onClick={storeReceipt}
                    >
                        Создать чек
                    </button>

                    <p>
                        Чек будет создан на сумму{` `}
                        {formatMoney(order, `total_amount`)}
                    </p>
                </div>
            )}

            <button
                className="btn btn-info"
                disabled={isLoading}
                onClick={(): void => setModalIsOpened(true)}
            >
                Создать чек на произвольную сумму
            </button>

            {modalIsOpened && (
                <StoreCustomReceiptModal
                    closeModal={(): void => setModalIsOpened(false)}
                />
            )}
        </div>
    );
}
