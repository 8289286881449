import React from "react";
import Select, { SingleValue } from "react-select";
import { useAccessPermissionsContext } from "@admin/pages/AccessPermissionsEditor/AccessPermissionsContext";
import { AccessObjectModel } from "@admin/domain/access/models/AccessObjectModel";
import { SelectOption } from "@admin/utils/types";

export default function AccessObjectsSearch() {
    const { selectedAccessGroup, setSelectedAccessObject } =
        useAccessPermissionsContext();

    const accessObjects = useAccessPermissionsContext().accessObjects.filter(
        (accessObject: AccessObjectModel): boolean => {
            if (!selectedAccessGroup) {
                return false;
            }

            return accessObject.scope === selectedAccessGroup.scope;
        },
    );

    const setAccessObject = (option: SingleValue<SelectOption>) => {
        setSelectedAccessObject(
            accessObjects.find(
                (accessObject: AccessObjectModel) =>
                    accessObject.id === option?.value,
            ) || null,
        );
    };

    const options = accessObjects.map((accessObject: AccessObjectModel) => ({
        label: accessObject.title,
        value: accessObject.id,
    }));

    // noinspection JSUnusedGlobalSymbols
    return (
        <div className="col-6">
            <label className="form-label">Объекты доступа</label>
            <Select
                options={[{ label: ``, value: 0 }].concat(options)}
                classNames={{
                    control: () => `form-select`,
                }}
                styles={{
                    control: () => ({
                        padding: `5px 0px`,
                        height: `45px`,
                        display: `flex`,
                    }),
                    clearIndicator: () => ({
                        marginRight: `35px`,
                    }),
                }}
                components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                }}
                classNamePrefix="cms"
                placeholder={null}
                onChange={setAccessObject}
                isClearable
            />
        </div>
    );
}
