import { type ReactNode } from "react";

import ComplexPromotionEditor from "@admin/pages/ComplexPromotionEditor";
import PromoCodeEditor from "@admin/pages/PromoCodeEditor";
import DeliveryPointForm from "@admin/pages/DeliveryPointEditor";
import ProductForm from "@admin/pages/ProductEditor";
import ProductCategoryEditor from "@admin/pages/ProductCategoryEditor";
import NotificationEditor from "@admin/pages/NotificationEditor";
import UniquePageEditor from "@admin/pages/UniquePageEditor";
import TextPageEditor from "@admin/pages/TextPageEditor";
import DeliveryMethodNotificationsEditor from "@admin/pages/DeliveryMethods/DeliveryMethodNotificationsEditor";
import DeliveryMethodGeneralConditionsEditor from "@admin/pages/DeliveryMethods/DeliveryMethodGeneralConditionsEditor";
import DeliveryMethodEditor from "@admin/pages/DeliveryMethods/DeliveryMethodEditor";
import DeliveryMethodGroupEditor from "@admin/pages/DeliveryMethods/DeliveryMethodGroupEditor";
import ThemeEditor from "@admin/pages/ThemeEditor";
import RubricEditor from "@admin/pages/RubricEditor";
import CustomPageEditor from "@admin/pages/CustomPageEditor";
import NewsArticleEditor from "@admin/pages/NewsArticleEditor";
import ChapterEditor from "@admin/pages/ChapterEditor";
import ArticleEditor from "@admin/pages/ArticleEditor";
import OrderEditor from "@admin/pages/OrderEditor";
import SeoParamsEditor from "@admin/pages/SeoParamsEditor";
import FooterEditor from "@admin/pages/FooterEditor";
import DeliveryMethodRegionEditor from "@admin/pages/DeliveryMethods/DeliveryMethodRegionEditor";
import LogsList from "@admin/pages/LogsList";
import AccessPermissionsEditor from "@admin/pages/AccessPermissionsEditor";

type Route = {
    path: string;
    element: ReactNode;
};

const routes: Route[] = [
    {
        path: `/admin/complex_promotions/create/amount`,
        element: <ComplexPromotionEditor type="amount" />,
    },
    {
        path: `/admin/complex_promotions/create/content`,
        element: <ComplexPromotionEditor type="content" />,
    },
    {
        path: `/admin/complex_promotions/:id`,
        element: <ComplexPromotionEditor />,
    },
    {
        path: `/admin/promo_codes/:id`,
        element: <PromoCodeEditor />,
    },
    {
        path: `/admin/delivery_points/:id`,
        element: <DeliveryPointForm />,
    },
    {
        path: `/admin/products/:id`,
        element: <ProductForm />,
    },
    {
        path: `/admin/product_categories/create`,
        element: <ProductCategoryEditor />,
    },
    {
        path: `/admin/product_categories/:id/edit`,
        element: <ProductCategoryEditor />,
    },
    {
        path: `/admin/notifications/:id`,
        element: <NotificationEditor />,
    },
    {
        path: `/admin/unique_pages/:id`,
        element: <UniquePageEditor />,
    },
    {
        path: `/admin/text_pages/:id`,
        element: <TextPageEditor />,
    },
    {
        path: `/admin/custom_pages/:id`,
        element: <CustomPageEditor />,
    },
    {
        path: `/admin/delivery_method_notifications`,
        element: <DeliveryMethodNotificationsEditor />,
    },
    {
        path: `/admin/delivery_method_general_conditions`,
        element: <DeliveryMethodGeneralConditionsEditor />,
    },
    {
        path: `admin/delivery_methods/:id/edit`,
        element: <DeliveryMethodEditor />,
    },
    {
        path: `/admin/delivery_method_groups/create`,
        element: <DeliveryMethodGroupEditor />,
    },
    {
        path: `/admin/delivery_method_groups/:id?/edit`,
        element: <DeliveryMethodGroupEditor />,
    },
    {
        path: `/admin/themes/create`,
        element: <ThemeEditor />,
    },
    {
        path: `/admin/themes/:id/edit`,
        element: <ThemeEditor />,
    },
    {
        path: `/admin/rubrics/:id`,
        element: <RubricEditor />,
    },
    {
        path: `/admin/news/:id`,
        element: <NewsArticleEditor />,
    },
    {
        path: `/admin/chapters/:id`,
        element: <ChapterEditor />,
    },
    {
        path: `/admin/articles/:id`,
        element: <ArticleEditor />,
    },
    {
        path: `/admin/seo/templates/*`,
        element: <SeoParamsEditor />,
    },
    {
        path: `/admin/footer`,
        element: <FooterEditor />,
    },
    {
        path: `/admin/delivery_method_region_configs/:id`,
        element: <DeliveryMethodRegionEditor />,
    },
    {
        path: `/admin/orders/:id`,
        element: <OrderEditor />,
    },
    {
        path: `/admin/logs`,
        element: <LogsList />,
    },
    {
        path: `/admin/access_permissions`,
        element: <AccessPermissionsEditor />,
    },
];

export default routes;
