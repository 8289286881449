import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { v4 as uuid } from "uuid";

import {
    initLanguageKeyObject,
    LanguageKeyObject,
} from "@admin/domain/languages/Languages";
import { FileModel } from "@admin/domain/files/model/FileModel";
import Fields from "./Fields";
import MobileAppFields from "./MobileAppFields";

type Props<T> = {
    showModal: boolean;
    closeModal: () => void;
    onSubmit: (data: T) => void;
    editableBanner: T | undefined;
    withMobileAppBanner?: boolean;
};

export type Banner = {
    id: string | number;
    tag: LanguageKeyObject;
    title: LanguageKeyObject;
    description: LanguageKeyObject;
    button_text: LanguageKeyObject;
    url: string;
    file: FileModel | null;
    mobile_app_title: LanguageKeyObject;
    mobile_app_description: LanguageKeyObject;
    mobile_app_url: string;
    mobile_app_file: FileModel | null;
    with_mobile_app_file: boolean;
};

export default function AddBannerModal<T extends Banner>(props: Props<T>) {
    const [banner, setBanner] = useState<Banner>(
        props.editableBanner || {
            id: uuid(),
            tag: initLanguageKeyObject(),
            title: initLanguageKeyObject(),
            description: initLanguageKeyObject(),
            button_text: initLanguageKeyObject(),
            url: ``,
            file: null,
            mobile_app_title: initLanguageKeyObject(),
            mobile_app_description: initLanguageKeyObject(),
            mobile_app_url: ``,
            mobile_app_file: null,
            with_mobile_app_file: false,
        },
    );

    const submit = (): void => {
        // @ts-ignore
        props.onSubmit({ ...banner, file: banner.file as FileModel });
        props.closeModal();
    };

    return (
        <Modal centered show={props.showModal} onHide={props.closeModal}>
            <Modal.Header>
                <Modal.Title>
                    {props.editableBanner ? `Обновление` : `Добавление`} баннера
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Fields setBanner={setBanner} banner={banner} />
                {props.withMobileAppBanner && (
                    <MobileAppFields setBanner={setBanner} banner={banner} />
                )}
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-secondary"
                    onClick={props.closeModal}
                >
                    Закрыть
                </button>
                <button className="btn btn-primary" onClick={submit}>
                    {props.editableBanner ? `Обновить` : `Добавить`}
                </button>
            </Modal.Footer>
        </Modal>
    );
}
